const debug = false

export const Console = (msg) => {
    return {
        log: () => {
            if (debug) console.log('[Log] :', JSON.stringify(msg))
        },
        error: () => {
            if (debug) console.error('[Error] :', msg)
        },
        info: () => {
            if (debug) console.info('[Info] :', msg)
        }
    }
}
