import React from 'react';
import * as Colors from '../colors'
import { CheckCircle, ArrowBack } from '@material-ui/icons'
import { CardContent, Card, List, ListItemAvatar, ListItemText, ListItem, Typography, Button } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from '../css/functions'
import config from '../config'
const mobile = isMobile()
const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 0,
        borderColor: 'whitesmoke',
        flex: '1 1 20rem',
        padding: '1rem',
        marginTop: mobile ? 15 : 0,
    },
    thankyou: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    icon: {
        width: 50,
        height: 50,
        color: Colors.colors.primary
    },
    card: {
        width: 300,
        // boxShadow: `0 14px 28px white, 0 10px 10px ${Colors.colors.primary};`
    },
    ul: {
        padding: 0,
        listStyleType: 'none',
        alignItems: 'center',
        marginBottom: '1rem'
    },
    li: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1rem'
    },

}))
function ThanYou() {
    const classes = useStyles()
    return <div className={classes.root} >
        <ul className={classes.ul}>
            <li className={classes.li}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography gutterBottom>
                            <h2 className={classes.thankyou}> Thank You !</h2>
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <CheckCircle className={classes.icon} />
                                </ListItemAvatar>
                                <ListItemText>
                                    <h4> Your order have been received.</h4>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>

            </li>
            <li className={classes.li}>
                {Object.keys(config.bankAccount).length > 0 ?
                    <div>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography gutterBottom>
                                    <h2 style={{ fontWeight: 'bold' }}> Bank Account</h2>
                                </Typography>
                                <List>
                                    {
                                        Object.keys(config.bankAccount).map((account, index) => {
                                            return (
                                                <ListItem style={{ padding: 0 }} key={index}>
                                                    <ListItemAvatar>
                                                        <h3 style={{ fontWeight: 'bold', color: Colors.colors.primary }}> {account}</h3>
                                                    </ListItemAvatar>
                                                    <ListItemText>
                                                        <h2 style={{ fontWeight: 'bold' }}> {config.bankAccount[account]}</h2>
                                                    </ListItemText>
                                                </ListItem>
                                            )
                                        })}
                                </List>
                            </CardContent>
                        </Card>
                    </div> : <div />
                }

            </li>
            <li className={classes.li}>
                <div style={{ alignItems: 'flex-end' }}>
                    <Link to='/'>
                        <Button
                            variant="contained"
                            size="medium"
                            style={{ background: Colors.colors.primary }}
                            startIcon={<ArrowBack />}
                        >
                            <b style={{ color: 'whitesmoke' }}>  Go Back</b>
                        </Button>
                    </Link>
                </div>
            </li>
        </ul>
    </div >

}

export default ThanYou;