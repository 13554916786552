import React from "react";
import config from '../config'
import { Phone, LocationOn } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from '../css/functions'
const mobile = isMobile()
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        display: mobile ? 'block' : 'flex',
        alignContent: 'flex-start',
        marginTop: mobile ? 0 : 7
    },
    contentItem: {
        marginLeft: 12,
    },
    contentAddress: {
        marginLeft: 15
    },
    contentText: {
        marginLeft: 12,
    },
    copyright: {
        marginTop: 5,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    }
}))
const Footer = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.copyright}>
                <span>
                    &copy;
                     <a href={config.businessUrl} style={{ color: 'whitesmoke' }}>
                        {config.businessName}.</a> 2021 All rights reserved.
                       </span>
            </div>
            <div className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.contentItem}>
                        <Phone /> {config.contact.phone}
                    </div>
                    <div className={classes.contentAddress}>
                        <LocationOn /> {config.contact.address}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer