import Axios from "axios";
import config from '../config'
import {
    ADD_SHIPPING
} from "../constants/shippingConstants";
const getShipping = () => async (dispatch) => {
    const { data } = await Axios.get(`${config.posUrl}/getShippingFee/${config.merchantId}`);
    let obj = { fee: [] }
    if (data && data.status !== 'error' && data.data) {
        obj = { ...obj, fee: data.data }
    }
    dispatch({ type: ADD_SHIPPING, payload: { ...obj } });
}
export { getShipping };