import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
  productListReducer,
  productDetailsReducer,
  productSaveReducer,
  productDeleteReducer,
  productReviewSaveReducer,
} from './reducers/productReducers';
import { cartReducer } from './reducers/cartReducers';
import {
  userSigninReducer,
  userRegisterReducer,
  userUpdateReducer,
  phoneAuthReducer,
  phoneVerifyReducer
} from './reducers/userReducers';
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  myOrderListReducer,
  orderListReducer,
  orderDeleteReducer,
} from './reducers/orderReducers';
import { itemListReducer, fetchItemReducer } from "./reducers/itemReducers";
import { cartItemReducer } from "./reducers/cartItemReducers";
import { categoryListReducer } from "./reducers/categoryReducers";
import { shippingFeeReducer } from "./reducers/shippingFeeReducer";
import toggleReducer from './reducers/toggleReducers'
import cartToggleReducer from './reducers/cartToggleReducers'
import utilReducer from './reducers/utilReducers'
import * as asyncInitialState from 'redux-async-initial-state';
import * as Db from './db'
const initialState = {
  cart: { cartItems: [], shipping: undefined, payment: {} },
  userSignin: { userInfo: undefined },
  cartItem: { items: [], shipping: undefined, payment: {} },
  items: { items: [] },
  categoryList: { categories: [{ name: 'All', id: 'All' }], loading: true, fetched: false }
};
const rootReducers = {
  util: utilReducer,
  itemList: itemListReducer,
  fees: shippingFeeReducer,
  items: fetchItemReducer,
  categoryList: categoryListReducer,
  productList: productListReducer,
  productDetails: productDetailsReducer,
  phoneAuth: phoneAuthReducer,
  phoneVerify: phoneVerifyReducer,
  cart: cartReducer,
  toggle: toggleReducer,
  cartToggle: cartToggleReducer,
  cartItem: cartItemReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  productSave: productSaveReducer,
  productDelete: productDeleteReducer,
  productReviewSave: productReviewSaveReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  userUpdate: userUpdateReducer,
  myOrderList: myOrderListReducer,
  orderList: orderListReducer,
  orderDelete: orderDeleteReducer,

}

// We need outerReducer to replace full state as soon as it loaded
const reducer = asyncInitialState.outerReducer(combineReducers({
  ...rootReducers,
  // We need innerReducer to store loading state, i.e. for showing loading spinner
  // If you don't need to handle loading state you may skip it
  asyncInitialState: asyncInitialState.innerReducer,
}));
const loadStore = (getCurrentState) => {
  return new Promise(resolve => {
    Promise.all([
      Db.getUser(),
      Db.getCartItems(),
      Db.getShipping(),
    ]
    ).then(values => {
      const user = values[0], items = values[1], ship = values[2]
      let _state = { ...initialState }
      if (user !== null) {
        _state = { ..._state, userSignin: { userInfo: { ...user } } }
      }
      if (Array.isArray(items)) {
        _state = { ..._state, cartItem: { ..._state.cartItem, items: items } }
      }
      if (ship !== null) {
        _state = { ..._state, cartItem: { ..._state.cartItem, shipping: { ...ship } } }
      }
      resolve({
        // reuse state that was before loading current user
        ...getCurrentState(),
        // and replace only `currentUser` key
        ..._state,
      }
      )
    })
  }
  )
}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(asyncInitialState.middleware(loadStore), thunk))
);
export default store;
