import { ADD_SHIPPING } from "../constants/shippingConstants";
export const shippingFeeReducer = (state = { fetchedFee: false, fee: [] }, action) => {
    switch (action.type) {
        case ADD_SHIPPING:
            const { fee } = action.payload
            return { ...state, fetchedFee: true, fee: fee };
        default:
            return state;
    }
}
